<template @resize="resizeFunction">
  <div v-if="!isPortrait" class="tw-w-full tw-grid tw-grid-cols-[33%,67%]">
    <Chat :is-chat-full-width="typeOfShow === 1" :typeOfShow="typeOfShow"  @changePage="changePage" v-if="typeOfShow !==2" :chat-words.sync="chatWords" :is-new-chat.sync="isNewChat"  @changeIsNewChat="changeIsNewChat" />
    <ChatBox v-if="typeOfShow === 1" :type-of-show.sync="typeOfShow" :is-new-chat="isNewChat" @getListChat="getListChat"   />
    <film-words ref="filmWord" v-if="typeOfShow === 2" :typeOfShow="typeOfShow"  @changePage="changePage" />
    <div class="tw-bg-[#F5F7F6] tw-p-6 tw-w-full tw-flex tw-flex-col tw-h-screen tw-gap-y-4"  v-if="typeOfShow === 0 || typeOfShow === 2">
<!--      <div class="tw-grid tw-grid-rows-[1fr_80px] tw-gap-y-4">-->
      <div class="tw-flex tw-justify-between tw-gap-x-6 wordContainer tw-overflow-hidden" >
        <div  class="tw-grid  tw-grid-rows-2 tw-gap-y-4 tw-h-full tw-bg-white tw-rounded-xl tw-py-2 tw-overflow-y-scroll tw-grid-cols-2" style="" >
          <div v-for="(content, index) in educational_points" :key="index" class="tw-flex" :class="index % 2 === 1 ? 'tw-justify-self-end' : ''">
            <word ref="wordComponent" :item="content" :index="index" :typeOfShow="typeOfShow" @initialWord="initialWords" @addNewWord="addNewWord" ></word>
          </div>
        </div>

        <div class="tw-flex tw-gap-y-6 tw-flex-col" >
          <v-btn class="ma-0" icon small  @click="changePage(typeOfShow===2 ? 0 : 2)">
            <img :src="typeOfShow === 0 ? maximize : minimize" alt="">
          </v-btn>
          <v-menu
              v-model="menuLang"
              nudge-left="150px"
              nudge-top="100px"
              rounded="12px"
              :close-on-content-click="false"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-icon v-bind="attrs"
                      v-on="on" color="#0B131A">mdi-web</v-icon>
            </template>
            <div class="tw-bg-white tw-p-6 tw-rounded-xl">
              <div class="tw-flex tw-justify-between">
                <div class="tw-text-base tw-font-bold">Language</div>
                <v-btn icon small @click="menuLang = false">
                  <img src="../../src/assets/svg/close.svg">
                </v-btn>
              </div>
              <div class="tw-text-sm tw-font-[500] tw-mt-6">Translate the words and expressions to</div>
              <div class="tw-flex tw-gap-x-4 tw-mt-4">
                <div v-for="(item, index) in languages" @click="changeLangFunc(item)" :key="index" class="tw-flex tw-gap-x-1 tw-mx-1">
                  <img :src="item.flag" alt="">
                  <span class="tw-text-xs" :class="getLanguages?.eduPoint_language_selected === item.id ? 'tw-text-[#222222] tw-font-medium' : 'tw-text-[#888888]'" >{{item.title}}</span>
                </div>
              </div>
              <div class="tw-text-sm tw-font-[500] tw-mt-6">Chat with the Smart Assistant in</div>
              <div class="tw-grid tw-grid-cols-3 tw-gap-y-4 tw-gap-x-4 tw-mt-4">
                <div v-for="(item, index) in languagesChat" @click="changeChatLangFunc(item)" :key="index" class="tw-flex tw-gap-x-1 tw-mx-1">
                  <img style="width: 18px;height: 18px" :src="item.flag" alt="">
                  <span class="tw-text-xs" :class="getLanguages?.chat_languages_selected === item.id ? 'tw-text-[#222222] tw-font-medium' : 'tw-text-[#888888]'">{{item.title}}</span>
                </div>
              </div>
            </div>
          </v-menu>
          <v-menu
              nudge-left="150px"
              nudge-top="100px"
              rounded="12px"
              :close-on-content-click="false"
              v-model="tuneItemDialog"
              min-width="40%"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-icon v-bind="attrs"
                      v-on="on" color="#0B131A">mdi-tune</v-icon>
            </template>
            <div class="tw-flex tw-flex-col tw-bg-white tw-rounded-12">
              <div class="tw-flex tw-justify-between tw-pt-4 tw-pb-2 tw-border-b tw-border-b-[#F5F7F6] tw-px-4">
                <span class="tw-font-semibold">Show me</span>
                <v-icon @click="tuneItemDialog = false">mdi-close</v-icon>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-py-2">
                <div class="tw-flex tw-flex-col tw-gap-y-4 tw-px-4 ">
                  <span class="tw-text-sm tw-font-semibold">Level</span>
                  <div v-for="(item,index) in getShowMe?.eduPoint_levels" class="tw-flex  tw-items-center" :key="index">
                    <v-checkbox  :ripple="false" style="margin: 0 !important;padding: 0!important;" hide-details  dense v-model="checkboxStates[index].value" @change="changeCheckbox($event,index)"></v-checkbox>
                    <span class="tw-text-xs" style="line-height: 20px">{{item.content}}</span>
                  </div>
                </div>
                <div class="tw-flex tw-flex-col tw-gap-y-4">
                  <span class="tw-text-sm tw-font-semibold">items</span>
                  <div v-for="(item,index) in getShowMe?.eduPoint_types" :key="index" class="tw-flex tw-items-center">
                    <v-checkbox :ripple="false" style="margin: 0 !important;padding: 0!important;" hide-details  dense v-model="levelItemCheckbox[index].value" @change="changeItemCheckbox($event,index)" ></v-checkbox>
                    <span class="tw-text-xs" style="line-height: 20px">{{item.content}}</span>
                  </div>
                </div>

              </div>
            </div>
          </v-menu>
        </div>
      </div>
      <Player @play="play" class="tw-h-[80px]"   :typeOfShow.sync="typeOfShow"/>
<!--      </div>-->
    </div>
  </div>
  <div v-else class="tw-flex tw-justify-center tw-h-full tw-w-full tw-items-center">
    please rotate your phone!
  </div>
</template>

<script>
import word from "@/components/common/word";
import Chat from "@/components/Chat/Chat.vue"
import Player from "@/components/Player/Player.vue";
import France from "../assets/France.svg"
import Germany from "../assets/Germany.svg"
import Spain from "../assets/Spain.svg"
import Arabic from "../assets/svg/Arabic.svg";
import Portuguese from "../assets/svg/Portuguese.svg";
import English from "../assets/svg/American.svg";
import firstLAng from "../assets/svg/firstLang.svg";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {changeLanguage} from "@/api/settingAPI";
import minimize from "../assets/svg/minimize.svg"
import maximize from "../assets/svg/changeScreen.svg"
import {changeShowMe} from "@/api/settingAPI";
import ChatBox from "@/components/Chat/ChatBox.vue";
import FilmWords from "@/components/FilmWords.vue";
export default {
  name: "wordsComponent",
  components:{
    FilmWords,
    ChatBox,
    word,
    Chat,
    Player
  },
  data:()=>({
    minimize,
    maximize,
    English,
    Spain,
    Germany,
    France,
    Arabic,
    Portuguese,
    firstLAng,
    chatWords : [],
    typeOfShow : 0, // 0 is chat and translate , 1 is chat only 2 is translate only
    tuneItemDialog : false,
    tuneLevel : null,
    checkboxStates : [],
    levelItemCheckbox : [],
    playerKey : 1,
    currentLevel : null,
    isPortrait : false,
    parseTime: [],
    educational_points : [],
    activeLang: '',
    activeChatLang: '',
    menuLang: false,
    isNewChat : false,
    pointerX : null,
    pointerY : null
  }),
  methods:{
    ...mapActions({
      fetchAllMessages : "movieMessages/fetchAllMessages",
      fetchMovieDataTexts : "movieWave/fetchMovieDataTexts",
      fetchLanguages: "setting/fetchLanguages",
      fetchChatHistories : "Chat/fetchChatHistories",
      fetchCurrentFilm : "movies/fetchCurrentFilm",
      fetchShowMe : "setting/fetchShowMe",
      fetchCurrentFilmWords : "movies/fetchCurrentFilmWords"


    }),
    ...mapMutations({
      changeCurrentChat : "Chat/CHANGE_CURRENT_CHAT",
      setMarginLeftParameter : "setting/SET_MARGIN_LEFT_PARAMETER",
      setPlayInterval : "setting/SET_PLAY_INTERVAL",
      setCurrentTime : 'setting/SET_CURRENT_TIME',
      setDraggerablePosition : "setting/SET_DRAGGERABLE_POSITION"

    }),
    play(){
      if (this.getPlayInterval === null)
        this.setPlayInterval(setInterval(()=>{
          if(this.getIsPlaying){
            if(Math.abs(this.getMarginLeftParameter) <this.getMovieWaveData.total_time * 10){
              if(this.getMarginLeftParameter > 0){
                this.setMarginLeftParameter(0)
              }

              if(this.currentPartTime === this.getMovieWaveData.total_time -1){
                this.$emit("pause")
              }
              else {
                if(document.getElementById("draggableContainer")!== undefined &&  document.getElementById("draggableContainer")!== null){
                  document.getElementById("draggableContainer").style.left = `calc(50% + ${this.getMarginLeftParameter - 10}px )`
                }

                try {
                  this.setMarginLeftParameter(this.getMarginLeftParameter - 10)

                }catch (e) {
                  console.log(e);
                }
                // console.log(this.getMarginLeftParameter)
                this.findCurrentPartUnderPointer()
              }

            }
            else{
              this.$emit("pause")
            }

          }
          else{
            this.$emit("pause")
          }
        },1000))
    },
    findCurrentPartUnderPointer() {
      const containerRect = document.getElementById("draggableContainer")?.getBoundingClientRect();
      if(containerRect !== undefined){
        const relativePointerX = this.pointerX - containerRect.left;
        const partWidth = 10; // Assuming each part has a fixed width of 10px
        const partIndexUnderPointer = Math.floor(relativePointerX / partWidth);
        if(partIndexUnderPointer >= 0 && partIndexUnderPointer < this.getMovieWaveData.total_time) {
          const currentPart = this.getMovieWaveData.contents[partIndexUnderPointer];
          this.setCurrentTime(currentPart)
        }
      }
    },
    changeIsNewChat(){
      this.isNewChat = false
    },
    async getListChat(){
      try {
        await this.fetchChatHistories(this.$route.params.film_id)
      }catch (e) {
        console.log(e);
      }
      this.$emit("changeIsNewChat",false)

    },
    addNewWord(newValue){
      let object ={
        name : newValue.phrase,
        item_id: newValue.id,
        avatar : newValue.details.part_of_speech,
        item_type :"EduPoint"
      }
      this.chatWords.splice(1,0,object)
      this.isNewChat = true
      this.changeCurrentChat(object)

    },
    resizeFunction(){
      this.isPortrait = window.matchMedia("(orientation: portrait)").matches;
    },

    changeCheckbox(event,index){
      this.checkboxStates[index].value = event;
    },
    changeItemCheckbox(event,index){
      this.levelItemCheckbox[index].value = event

    },

    changePage(value){
      this.typeOfShow = value
      // this.playerKey +=1
    },
    calculateToSeconds(input){
      if (input === null || input === undefined)
        return 0
      let times = input.split(':')
      return parseInt(times[0])*3600 + parseInt(times[1])*60 + parseInt(times[2])
    },
    async initialWords(is_word){
      let params = {
        film_id : this.$route.params.film_id,
        payload : {
          start_time: 0,
          end_time : 60
        }
      }
      await this.fetchMovieDataTexts(params)
      if(is_word){
        for(let component of this.$refs?.wordComponent){
          component.isiHideLoading = false
        }

        await this.fetchCurrentFilmWords({film_id : this.$route.params.film_id})
        this.$refs.filmWord.currentTab = 0

      }

      this.parseTime = [...[]]
      for (const index in this.getMovieDataTexts) {
        this.parseTime.push({start_time: this.calculateToSeconds(this.getMovieDataTexts[index].start_time), end_time: this.calculateToSeconds(this.getMovieDataTexts[index].end_time)})
      }

      this.educational_points = this.movieDataTextsComputed;

    },
    async changeLangFunc(input){
      let object = {
        app_language : this.getLanguages.app_language_selected,
        eduPoint_language : input.id,
        chat_language : this.getLanguages.chat_languages_selected
      }
      try {
        this.menuLang = false
        await changeLanguage(object)
        await this.initialWords()
        await this.fetchLanguages()
      }catch (e) {
        console.log(e);
      }
    },
    async changeChatLangFunc(input){
      let object = {
        app_language : this.getLanguages.app_language_selected,
        eduPoint_language : this.getLanguages.eduPoint_language_selected,
        chat_language : input.id
      }
      try {
        this.menuLang = false
        await changeLanguage(object)
        await this.initialWords()
        await this.fetchLanguages()
      }catch (e) {
        console.log(e);
      }
    },

    initializeShowMe(){
      this.checkboxStates = new Array(this.getShowMe.eduPoint_levels.length).fill({id : '',value : false})
      for(let i=0;i<this.getShowMe.eduPoint_levels_selected.length;i++){
        this.checkboxStates[i] = {id : this.getShowMe.eduPoint_levels[i].id , value : this.getShowMe.eduPoint_levels_selected[i]}
      }

      this.levelItemCheckbox = new Array(this.getShowMe.eduPoint_types.length).fill({id : '' ,value : false})

      for(let i=0;i<this.getShowMe.eduPoint_types_selected.length;i++){
        this.levelItemCheckbox[i] = {id : this.getShowMe.eduPoint_types[i].id , value : this.getShowMe.eduPoint_types_selected[i]}
      }
    },

    prepareDataForShowMe(){
      let eduPoint_levels = []
      let eduPoint_types = []
      for(let item of this.checkboxStates){
        if(item.value){
          eduPoint_levels.push(item.id)
        }
      }

      for(let item of this.levelItemCheckbox){
        if(item.value){
          eduPoint_types.push(item.id)
        }
      }

      return {
        eduPoint_levels,
        eduPoint_types
      }

    }

  },
  watch:{
    getCurrentTime(){
      this.educational_points = this.movieDataTextsComputed;

    },
    async tuneItemDialog(newValue){
      if(newValue){
        await this.fetchShowMe()
        this.initializeShowMe()
      }
      else{
        this.prepareDataForShowMe()
        try {
          await changeShowMe({eduPoint_level: this.prepareDataForShowMe().eduPoint_levels, eduPoint_type :  this.prepareDataForShowMe().eduPoint_types})
          await this.initialWords()
        }catch (e) {
          console.log(e);
        }
      }
    },
    async menuLang(newValue){
      if(newValue){
        await this.fetchLanguages()
      }
    },
    getChatHistories: {
      handler: function(newValue) {
        this.chatWords = newValue.results
      },
      deep: true
    }
  },
  computed:{
    ...mapGetters({
      getMovieDataTexts : "movieWave/getMovieDataTexts",
      getLanguages: "setting/getLanguages",
      getChatHistories:  "Chat/getChatHistories",
      getCurrentFilm : "movies/getCurrentFilm",
      getShowMe : "setting/getShowMe",
      getMarginLeftParameter : "setting/getMarginLeftParameter",
      getMovieWaveData : "movieWave/getMovieWaveData",
      getIsPlaying : "setting/getIsPlaying",
      getPlayInterval : "setting/getPlayInterval",
      getCurrentTime : "setting/getCurrentTime",
      getDraggerablePosition : 'setting/getDraggablePosition',
      getChangeTimeFlag : "setting/getChangeTimeFlag"
    }),

    movieDataTextsComputed(){
      if(this.getMovieDataTexts){
        for (const index in this.parseTime) {
          if (this.getCurrentTime.time <= this.parseTime[index].end_time && this.getCurrentTime.time >= this.parseTime[index].start_time){
            return this.getMovieDataTexts[index].educational_points
          }
        }
      }
      return []

    },

    languages(){
      return [
        // {title : "English", flag : this.English, id: 1},
        {title : "Spanish", flag : this.Spain, id: 2},
        {title : "Germany" , flag: this.Germany, id: 3},
        { title : "France" , flag: this.France, id: 4},
        {title : "Arabic", flag : this.Arabic, id: 5},
        {title : "Portuguese", flag : this.Portuguese, id: 6},
      ]
    },
    languagesChat(){
      return [
        {title : "English", flag : this.English, id: 1},
        {title : "Spanish", flag : this.Spain, id: 2},
        {title : "Germany" , flag: this.Germany, id: 3},
        { title : "France" , flag: this.France, id: 4},
        {title : "Arabic", flag : this.Arabic, id: 5},
        {title : "Portuguese", flag : this.Portuguese, id: 6},

        {title : "My first language", flag : this.firstLAng, id: 7},
      ]
    }

  },
  async mounted() {
    const element = document.getElementById('pointer');
    if(element){
      const rect = element?.getBoundingClientRect();
      this.pointerX = rect.left + window.scrollX;
      this.pointerY= rect.top + window.scrollY;
    }



    window.addEventListener('resize', this.resizeFunction);
    this.resizeFunction(); // Initial check
    let params = {
      film_id : this.$route.params.film_id,
      payload : {
        start_time: 0,
        end_time : 60
      }
    }
    await this.fetchMovieDataTexts(params)
    for (const index in this.getMovieDataTexts) {
      this.parseTime.push({start_time: this.calculateToSeconds(this.getMovieDataTexts[index].start_time), end_time: this.calculateToSeconds(this.getMovieDataTexts[index].end_time)})
    }
    await this.fetchCurrentFilm(this.$route.params.film_id)
    await this.fetchChatHistories(this.$route.params.film_id)
    this.changeCurrentChat(this.getChatHistories?.results[0])
    await this.fetchShowMe()
    this.initializeShowMe()
    // await this.initialWords()



  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFunction);
  },
}
</script>

<style>
.wordContainer{
  height: calc(100% - 64px) !important;
}
.v-menu__content {
  box-shadow: none;
}
</style>