

const getUserProfileAPI=async function(){
    return profileFunction().then((value)=>{
        return value
    })
}

const getUserTasksAPI= async function(){
    return getUserTaskFunction().then((value)=>{
        return value
    })
}


const  getUserTaskFunction=() =>new Promise(function (resolve){
    resolve({
        data : {
            results : [
                {id : 1,src : '' , description  :''},
                // {id : 2,src : '' , description  :''},
                // {id : 3,src : '' , description  :''},
                // {id : 4,src : '' , description  :''},
            ]
        }
    })
})

const profileFunction = ()=>new Promise(function (resolve){
    resolve({
        data : {
            username : 'Matt',
            places : '1',
            phone_number : '989121111111',
            avatar : null
        }
    })
})

export {
    getUserProfileAPI,
    getUserTasksAPI
}