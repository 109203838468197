<template>
  <div class="tw-flex tw-flex-col tw-p-6">
      <UserTopBar>
        <template>
          <v-icon class="tw-w-6 tw-h-6" color="#0B131A" @click="backToHome">mdi-chevron-left</v-icon>
        </template>
      </UserTopBar>
    <SearchBox class="tw-my-6" @search="searchFunction"  />
    <div class="tw-flex tw-flex-col tw-gap-y-1">
      <span class="tw-text-xl tw-text-[#222222] tw-font-semibold">{{$route.params.id === '1' ? 'Drama' : $route.params.id === '2' ? 'Comedy' : 'Actions'  }}</span>
      <span class="tw-text-[#CBCBCB] tw-text-sm">Choose video to watch</span>
    </div>
    <HardnessLevel class="tw-mt-4 tw-mb-8" :active-index.sync="activeIndex" @changeActiveHardness="changeActiveHardness" />
    <div v-if="!loading">
      <OneGenreFilm v-for="(film,index) in getCurrentGenreFilms?.results" :key="index" :film="film" class="tw-mb-4" />
    </div>
    <v-progress-circular v-else size="24" indeterminate class="tw-mx-auto tw-my-24" color="#1c78f2" />
  </div>
</template>

<script>
import UserTopBar from "@/components/common/UserTopBar.vue";
import SearchBox from "@/components/common/searchBox.vue";
import HardnessLevel from "@/components/common/hardnessLevels.vue";
import {mapActions, mapGetters} from "vuex";
import OneGenreFilm from "@/components/genre/oneGenreFIlm.vue";

export default {
  name : "oneGenre",
  components: {OneGenreFilm, HardnessLevel, SearchBox, UserTopBar},
  data:()=>({
    activeIndex : 1,
    search : '',
    loading : false
  }),
  async mounted() {
    await this.callAPI()
  },
  methods:{
    ...mapActions({
      fetchCurrentGenreFilms : "movies/fetchCurrentGenreFilms"
    }),
    async changeActiveHardness(value){
      this.activeIndex = value
      await this.callAPI()
    },
    async searchFunction(search){
      this.search = search
      await this.callAPI()
    },
    async callAPI(){
      this.loading = true
      await this.fetchCurrentGenreFilms(this.payload)
      this.loading = false
    },
    backToHome(){
      this.$router.push(`/`)
    }
  },
  computed:{
    ...mapGetters({
      getCurrentGenreFilms  : 'movies/getCurrentGenreFilms'
    }),
    payload(){
      return {
        genre : this.$route.params.id,
        level : this.activeIndex,
        search : this.search
      }
    }
  }
}

</script>

<style scoped>

</style>