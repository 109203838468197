
<template>
  <div style="direction: rtl; height: 100vh;" :class="[isChatFullWidth ? 'tw-w-full tw-rounded-xl' : 'tw-rounded-l-xl',typeOfShow === 1 ? 'tw-grid-rows-[50px_1fr]' : 'tw-grid-rows-[50px_70px_1fr] tw-pb-3']" class="tw-bg-white tw-grid  " >
    <div class="tw-px-6 tw-py-0 tw-flex tw-justify-between tw-items-center tw-row-span-1">
      <div class="tw-flex tw-gap-x-4">
        <v-btn class="ma-0" icon small  @click="changeTypeOfShow">
          <img :src="typeOfShow === 0 ? maximize : minimize" alt="">
        </v-btn>
      </div>
      <div class="tw-flex tw-items-center tw-gap-x-2">
        <span class="tw-text-sm tw-text-left">{{getCurrentFilm?.results?.title}}</span>
        <v-icon class="tw-w-6 tw-h-6" color="#0B131A" @click="backToOneFilmPage">mdi-chevron-left</v-icon>
      </div>
    </div>
    <ChatWords class="tw-row-span-1" :chat-words="chatWords" :typeOfShow="typeOfShow"/>
    <ChatBox v-if="typeOfShow === 0" class="tw-row-span-1" :is-new-chat="isNewChat" @getListChat="getListChat" :type-of-show="typeOfShow"  />

  </div>

</template>

<script>
import ChatWords from "@/components/Chat/ChatWords.vue";
import ChatBox from "@/components/Chat/ChatBox.vue";
import minimize from "@/assets/svg/minimize.svg"
import maximize from "@/assets/svg/changeScreen.svg"
import {mapActions, mapGetters} from "vuex";
export default {
  name : "Chat",
  data:()=>({
    minimize,
    maximize
  }),
  components: {ChatWords,ChatBox},
  props:{
    isChatFullWidth: {
      type : Boolean,
      default : false
    },
    typeOfShow:{},
    chatWords:{},
    isNewChat : {},
  },
  methods:{
    ...mapActions({
      fetchChatHistories : "Chat/fetchChatHistories"
    }),
    backToOneFilmPage(){
      this.$router.push(`/film/${this.$route.params.film_id}`)
    },
    changeTypeOfShow(){
      if(this.typeOfShow === 0){
        this.$emit("changePage",1)
      }
      else if(this.typeOfShow === 1){
        this.$emit("changePage",0)
      }
    },
    async getListChat(){
      try {
        await this.fetchChatHistories(this.$route.params.film_id)
      }catch (e) {
        console.log(e);
      }
      this.$emit("changeIsNewChat",false)

    }
  },
  watch:{
  },
  computed:{
    ...mapGetters({
      getCurrentFilm : "movies/getCurrentFilm"
    })
  }

}
</script>


<style scoped>

</style>