import httpClient from "@/api/httpClient";

const chatHistoriesAPI=(id)=>httpClient.get(`https://vidipen.com/film/list-chat/${id}`)

const currentChatHistoryAPI=(item)=>httpClient.get(`https://vidipen.com/film/chat_history/${item.item_type}/${item.chat_id}`)

const eduPointChat =(payload)=>httpClient.post('https://vidipen.com/eduPoint/chat/',payload)
const filmChat =(payload)=>httpClient.post('https://vidipen.com/film/chat/',payload)

export {
    eduPointChat,
    filmChat,
    chatHistoriesAPI,
    currentChatHistoryAPI
}