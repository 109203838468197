import {getUserProfileAPI, getUserTasksAPI} from "@/api/userprofileAPI"
import {getUserFilmWordsAPI} from "@/api/FilmWordAPI";
const state=({
    profile : null,
    userTasks : [],
    userWords : null
})

const actions={
    async fetchUserProfile({commit},payload) {
        let response = await getUserProfileAPI(payload)
        commit("SET_USER_PROFILE", response.data)
    },
    async fetchUserTasks({commit},payload){
        let response = await getUserTasksAPI(payload)
        commit("SET_USER_TASKS",response.data)
    },
    async fetchUserWords({commit},payload){
        let response = await getUserFilmWordsAPI(payload)
        commit("SET_USER_WORDS",response.data)
    }
}

const mutations ={
    SET_USER_PROFILE(state,data){
        state.profile = data
    },
    SET_USER_TASKS(state,data){
        state.userTasks = data
    },
    SET_USER_WORDS(state,data){
        state.userWords = data
    }
}
const getters ={
    getUserProfile(state){
        return state.profile
    },
    getUserTasks(state){
        return state.userTasks
    },
    getUserWords(){
        return state.userWords
    }
}
export  default {
    namespaced : true,
    state,
    actions,
    getters,
    mutations
}
