<template>
  <div class="tw-flex tw-w-full">
    <v-text-field v-model="searchText"  dense hide-details outlined :dark="dark"  :background-color="backgroundColor" style="border-radius: 61px" class="tw-p-4 tw-text-xs"  :placeholder="placeholder">
      <template #append>
        <v-icon :color="magnifyColor" :disabled="searchText === ''" @click="applySearch" >mdi-magnify</v-icon>
      </template>
    </v-text-field>

  </div>
</template>

<script>
  export default {
    name : 'searchBox',
    data:()=>({
      searchText : null
    }),
    methods:{
      applySearch(){
        if(this.searchText !==''){

          this.$emit("search",this.searchText)
        }
      }
    },
    watch:{
      searchText(newValue){
        if(newValue === ''){
          this.$emit("search",'')

        }
      }
    },
    props:{
      placeholder : {
        type : String,
        default : "search in 341252 movies..."
      },
      backgroundColor : {
        type : String,
        default : "#F5F7F6"
      },
      dark:{
        type : Boolean,
        default : false
      },
      magnifyColor : {
        type : String,
        default : '#888888'
      }
    }
  }
</script>

<style scoped>

</style>