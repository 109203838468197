import httpClient from "@/api/httpClient";

const getMovieWaveDataAPI = (film_id) => httpClient.get(`/film/wave/${film_id}`);

const getMovieDataTextsAPI = (params) => httpClient.get(`eduPoint/timeline/${params.film_id}`, params.payload)

const changeMarked = (params) => httpClient.post('/eduPoint/bookmarked/', params)
const changeGotIt = (params) => httpClient.post('/eduPoint/got-it/', params)

const sampleQuestionFilm = (film_id) => httpClient.get(`film/sample-questions/${film_id}`);
const sampleQuestionEdu = (eduPoint_id) => httpClient.get(`eduPoint/sample-questions/${eduPoint_id}`);

export {
    getMovieWaveDataAPI,
    getMovieDataTextsAPI,
    changeMarked,
    changeGotIt,
    sampleQuestionFilm,
    sampleQuestionEdu,

}
