import { getTopMoviesAPI,getCurrentFilmPropertyAPI,getAllMoviesAPI} from "@/api/moviesAPI";
import {getFilmWords} from "@/api/FilmWordAPI"

const state=({
    topMovies : [],
    currentGenreFilms : [], // this is for switching between hardness level and genre,
    currentFilm : null,
    currentFilmWords : []
})


const actions ={
    async fetchCurrentFilmWords({commit},payload){
      let response = await getFilmWords(payload)
      commit("SET_CURRENT_FILM_WORDS",response.data)
    },
    async fetchTopMovie({commit},payload){
        let response= await getTopMoviesAPI(payload)
        console.log(response.data);
        commit("SET_TOP_MOVIES",response.data)
    },
    async fetchCurrentGenreFilms({commit},payload){
        let response = await  getAllMoviesAPI(payload)
        commit("SET_CURRENT_GENRE_FILMS",response.data)
    },
    async fetchCurrentFilm({commit},payload){
      let response = await  getCurrentFilmPropertyAPI(payload)
        commit("SET_CURRENT_FILM",response.data)
    },
    changeCurrentFilmProperty({commit},film){
        commit("SET_CURRENT_FILM",film)
    }

}
const getters ={
    getTopMovies(state){
        return state.topMovies
    },
    getCurrentGenreFilms(state){
        return state.currentGenreFilms
    },
    getCurrentFilm(state){
        return state.currentFilm
    },
    getCurrentFilmWords(state){
        return state.currentFilmWords
    }
}

const mutations = {
    SET_TOP_MOVIES(state,data){
        state.topMovies = data
    },
    SET_CURRENT_GENRE_FILMS(state,data){
        state.currentGenreFilms = data
    },
    SET_CURRENT_FILM(state,data){
        state.currentFilm = data
    },
    SET_CURRENT_FILM_WORDS(state,data){
        state.currentFilmWords = data
    }

}

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters
}