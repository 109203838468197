import axios from "axios";
// import router from "@/router";
// import store from "@/store";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 1200000,

  headers: {
    "Content-Type": "application/json",
    "Authorization" : "Token d4528967dc758ca60715a7c49e6bec40ab6922d2"
  },
});

// const alt_axios = axios.create();
// httpClient.interceptors.response.use(
//   (response) => {
//     if (
//       response.request.responseURL.includes("login/admin/verify") ||
//       response.request.responseURL.includes("refresh-token")
//     ) {
//       httpClient.defaults.headers.common["Authorization"] =
//         "Token " + response.data.access;
//     }
//     return response;
//   },
//   async (error) => {
//     return new Promise((resolve, reject) => {
//       if(error.code === 'ERR_NETWORK'){
//         store.commit('alerts/SET_ALERT', {
//           text: 'شبکه دچار مشکل شده است',
//           color: "error",
//           type: "error",
//           isActive: true
//         })
//       }
//       if(error.code === 'ECONNABORTED'){
//         store.commit('alerts/SET_ALERT', {
//           text: 'ارتباط با سرور برقرار نیست',
//           color: "error",
//           type: "error",
//           isActive: true
//         })
//       }
//       if (error.response.status === 401) {
//         if (localStorage.getItem("refresh")) {
//           let refreshTokenVal = localStorage.getItem("refresh");
//           let lastRequest = error.config;
//           let params = { refresh: refreshTokenVal };
//           alt_axios
//             .post(`refresh-token/`, params, {
//               baseURL: process.env.VUE_APP_BASE_USER_URL,
//             })
//             .then((res) => {
//               localStorage.setItem("access", res.data.access);
//               localStorage.setItem("refresh", refreshTokenVal);
//               window.dispatchEvent(new Event("storage"));
//
//               httpClient.defaults.headers.common["Authorization"] =
//                 "Token " + localStorage.getItem("access");
//               lastRequest.headers["Authorization"] =
//                 "Token " + localStorage.getItem("access");
//               resolve(httpClient(lastRequest));
//             })
//             .catch(() => {
//               router.push({ name: "login" }).catch(() => {});
//             });
//         } else {
//           localStorage.removeItem("access");
//           router.push("/login").catch(() => {});
//           reject(error);
//         }
//       } else {
//         if(error.response.status===403){
//           store.state.alerts.alert={  text:"شما دسترسی لازم ندارید",
//             color: "error",
//             type: "error",
//             isActive: true}
//         }
//         if (error.response.status !== 404) {
//           if (error.response.status !== 500) {
//             reject(error);
//           } else {
//             router.push({ name: "500" }).catch(() => {});
//             reject(error);
//           }
//         } else {
//           router.push({ name: "404" }).catch(() => {});
//           reject(error);
//         }
//         reject(error);
//       }
//     });
//   }
// );

export default httpClient;
