import {getMovieWaveDataAPI,getMovieDataTextsAPI, sampleQuestionFilm, sampleQuestionEdu} from "@/api/movieWaveAPI";

const state=({
    movieWaveData : {},
    movieDataTexts : {},
    sampleQesFilm: null,
    sampleQesEdu: null,

})

const actions = {
    async fetchMovieWaveData({commit},payload){
        try {
            let response = await getMovieWaveDataAPI(payload)
            commit("SET_MOVIE_WAVE_DATA",response.data.data)
        }
        catch {
        //
        }
    },
    async fetchMovieDataTexts({commit},payload){
        try {
            let response = await getMovieDataTextsAPI(payload)
            console.log('response response', response);
            commit("SET_MOVIE_DATA_TEXTS",response.data)
        }
        catch {
            //
        }
    },
    async fetchSampleQuesFilm({commit},payload){
        try {
            let response = await sampleQuestionFilm(payload)
            commit("SET_SAMPLE_QUES_FILM",response.data)
        }
        catch {
            //
        }
    },
    async fetchSampleQuesEdu({commit},payload){
        try {
            let response = await sampleQuestionEdu(payload)
            commit("SET_SAMPLE_QUES_EDU",response.data)
        }
        catch {
            //
        }
    },

}

const getters = {
    getMovieWaveData(){
        return state.movieWaveData
    },
    getMovieDataTexts(){
        return state.movieDataTexts
    },
    getSampleQuesFilm(state){
        return state.sampleQesFilm
    },
    getSampleQuesEdu(state){
        return state.sampleQesEdu
    },


}

const mutations ={
    SET_MOVIE_WAVE_DATA(state,data){
        state.movieWaveData = data
    },
    SET_MOVIE_DATA_TEXTS(state,data){
        state.movieDataTexts = data
    },
    SET_SAMPLE_QUES_FILM(state, data){
        state.sampleQesFilm = data;
    },
    SET_SAMPLE_QUES_EDU(state, data){
        state.sampleQesEdu = data;
    },

}
export  default {
    namespaced : true,
    state,
    actions,
    getters,
    mutations
}