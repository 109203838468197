<template>
  <div class="tw-flex tw-gap-x-3">
    <div v-for="(level , index) in levels" class="tw-px-4 tw-py-2 tw-rounded-[33px]"
         :key="index" :class="index+1 === activeIndex ? activeIndex ===1 ? 'tw-bg-[#FEF4EF]' : activeIndex ===2 ?  'tw-bg-[#F2FBF6]' : 'tw-bg-[#F2F7FB]'  : 'tw-bg-[#FBFBFB]'" @click="changeHardness(index+1)">
      <span :class="index+1 === activeIndex? activeIndex ===1 ? 'tw-text-[#F9965F]' : activeIndex ===2 ?  'tw-text-[#7AD2A8]' : 'tw-text-[#7AB3D2]' : 'tw-text-[#999999]'" class="tw-text-[13px]">{{level}}</span>
    </div>

  </div>
</template>

<script>
export default {
  name : "hardnessLevel",
  data:()=>({
    levels : ['Elementary','Intermediate','Advanced']
  }),
  props : {
    activeIndex : {
      default :1,
    }
  },
  methods:{
    changeHardness(index){
      this.$emit("changeActiveHardness" , index )
    }
  }

}

</script>

<style scoped>

</style>