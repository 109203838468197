<template>
  <div id="player" class="tw-flex tw-flex-col tw-gap-y-5 tw-flex-1">
<!--    <span>{{currentText}}</span>-->
    <div class="tw-w-full tw-flex tw-justify-between tw-items-center ">
      <span>{{secondsToHms(getCurrentTime !== null ? getCurrentTime.time : 0)}}</span>
      <div class="tw-flex tw-gap-x-4 tw-items-center">
        <img :src="fastForwardIcon" alt="" style="transform: rotate(180deg)" @click="navigatePlayer(10,'back')">
        <v-icon color="#B9B9B9" class="tw-w-5 tw-h-5" @click="navigatePlayer(1,'back')" >mdi-chevron-left</v-icon>
        <div class="tw-w-10 tw-h-10 tw-bg-[#6196CF] tw-rounded-full tw-flex tw-justify-center tw-items-center" @click="playAndPause">
          <v-icon color="white">{{this.getIsPlaying ? 'mdi-pause' : 'mdi-play'}}</v-icon>
        </div>
        <v-icon color="#B9B9B9" class="tw-w-5 tw-h-5" @click="navigatePlayer(1,'forward')">mdi-chevron-right</v-icon>
        <img :src="fastForwardIcon" alt="" @click="navigatePlayer(10,'forward')" >
      </div>
      <span>{{secondsToHms(getMovieWaveData?.total_time -1)}}</span>
    </div>

    <TimeSeriesPlayer  @play="play" :typeOfShow="typeOfShow"   @changeIsPlaying="changeIsPlaying" ref="timeSeriesPlayer"  />

  </div>
</template>

<script>
// import WaveSurfer from "wavesurfer.js";

import TimeSeriesPlayer from "@/components/Player/TimeSeriesPlayer.vue";
import fastForwardIcon from "../../assets/fastForward.svg"
import {mapActions, mapGetters, mapMutations} from "vuex";


export default {
  name: 'Player',
  components: {TimeSeriesPlayer},
  data:()=>({
    wavesurfer: null,
    src : "https://webaudioapi.com/samples/audio-tag/chrono.mp3",
    fastForwardIcon,
  }),
  props:{
    typeOfShow:{}
  },
  async mounted () {

    await this.fetchMovieWaveData(this.$route.params.film_id)

  },
  methods: {
    ...mapActions({
      fetchMovieWaveData : "movieWave/fetchMovieWaveData",
    }),
    ...mapMutations({
      setIsPlaying : "setting/SET_IS_PLAYING",
      setMarginLeftParameter : "setting/SET_MARGIN_LEFT_PARAMETER"
    }),
    play(){
      this.$emit("play")
    },
    navigatePlayer(time,direction){
      this.$refs.timeSeriesPlayer.directionChange = direction
      this.$refs.timeSeriesPlayer.timeChange  =time

    },
    changeIsPlaying(value){
      this.setIsPlaying(value)
    },
    playAndPause(){
      this.setIsPlaying(!this.getIsPlaying)
    },
    secondsToHms(seconds) {
      if(!isNaN(seconds)){
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        // Format each component with leading zeros if necessary
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        // Return the formatted string
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      }
      return `Loading...`
      // Calculate hours, minutes, and remaining seconds

}


},
  computed:{
    ...mapGetters({
      getMovieWaveData : "movieWave/getMovieWaveData",
      getIsPlaying : "setting/getIsPlaying",
      getCurrentTime : "setting/getCurrentTime"
    })
  }
};
</script>

<style>
#player {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
</style>
