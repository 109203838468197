import httpClient from "@/api/httpClient";

const getFilmWords =(params)=>httpClient.get(`https://api.vidolingo.com/eduPoint/word-bank/film/${params.film_id}`,{
    params: {
        ...(params?.type ? {type: params?.type} : {}),
    },
})
const getUserFilmWordsAPI =(params)=>httpClient.get(`https://api.vidolingo.com/eduPoint/word-bank/user`,{
    params: {
        ...(params?.type ? {type: params?.type} : {}),
    },
})
export  {
    getFilmWords,
    getUserFilmWordsAPI
}