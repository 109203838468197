import {getAllMessagesAPI,getGuidanceMessagesAPI} from "@/api/movieMessageAPI";

const state=({
    allMessages : [],
    guidanceMessages: []
})

const actions = {
    async fetchAllMessages({commit},payload){
        let response = await getAllMessagesAPI(payload)
        commit("SET_ALL_MESSAGES",response.data)
    },
    async fetchGuidanceMessage({commit},payload){
        let response = await getGuidanceMessagesAPI(payload)
        commit("SET_GUIDANCE_MESSAGES",response.data)
    }


}


const getters = {
    getAllMessages(state){
        return state.allMessages
    },
    getGuidanceMessages(state){
        return state.guidanceMessages
    }
}

const mutations = {
    SET_ALL_MESSAGES(state,data){
        state.allMessages  = data
    },
    SET_GUIDANCE_MESSAGES(state,data){
        state.guidanceMessages = data
    }
}


export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters
}