import Vue from 'vue'
import Vuex from 'vuex'
import movieMessages from "@/store/modules/movieMessages";
import movieWave from "@/store/modules/movieWave";
import userprofile from "@/store/modules/userprofile";
import movies from "@/store/modules/movies";
import setting from "@/store/modules/setting";
import Chat from "@/store/modules/Chat";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    movieMessages,
    movieWave,
    userprofile,
    movies,
    setting,
    Chat

  }
})
