import {chatHistoriesAPI,currentChatHistoryAPI} from "@/api/chat";

const state=({
    histories : [],
    currentChat : null,
    currentChatHistory : [],

})

const actions = {
    async fetchChatHistories({commit},payload){
        let response = await chatHistoriesAPI(payload)
        commit("SET_CHAT_HISTORY",response.data)
    },
    async fetchCurrentChatHistory({commit},payload){
        try {
            let response = await currentChatHistoryAPI(payload)
            commit("SET_CURRENT_CHAT_HISTORY",response.data)
        }
        catch (e) {
            commit("SET_CURRENT_CHAT_HISTORY",[])
        }

    },


}

const getters={
    getCurrentChatHistory(state){
        return state.currentChatHistory
    },
    getChatHistories(state){
        return state.histories
    },
    getCurrentChat(state){
        return state.currentChat
    }

}

const mutations = {
    SET_CHAT_HISTORY(state,data){
        state.histories = data
    },
    SET_CURRENT_CHAT_HISTORY(state,data){
        state.currentChatHistory = data
    },
    CHANGE_CURRENT_CHAT(state,data){
        state.currentChat = data
    }


}

export default {
    namespaced :true,
    state,
    actions,
    getters,
    mutations
}