<template>
  <div class="tw-grid tw-grid-cols-8  tw-grid-rows-2  tw-gap-y-0 tw-px-6" :class="typeOfShow === 0 ? index %2 ===0 ? 'tw-border-r tw-border-r-[#D9D9D9]' : '' :typeOfShow === 2 ? index %3 !==2 ? 'tw-border-r tw-border-r-[#D9D9D9]' : '' :''">
    <div class="tw-col-span-6 tw-flex tw-items-center tw-text-sm tw-text-[#222222]" @click="addToArray">
        <span>{{ item.phrase }}</span>
    </div>
    <div class="tw-col-span-6 tw-row-start-2 tw-items-center tw-text-[#8E8E8E] tw-text-xs">
      <span>{{ item.meaning }}</span>
    </div>
    <div class="tw-col-span-2 tw-grid tw-grid-cols-2 tw-gap-x-4">
      <div class="tw-relative tw-grid tw-grid-cols-1 tw-grid-rows-1 tw-w-6 tw-text-center  tw-justify-items-center tw-items-center">
        <span :style="{background: colorMapType.background}" class="dot tw-col-span-full tw-row-span-full"></span>
        <span :style="{color: colorMapType.color}" class="tw-text-red-500 tw-col-span-full tw-row-span-full tw-text-[8px]">{{ item.details.part_of_speech_abbreviation }}</span>
      </div>
      <div class="tw-relative tw-grid tw-grid-cols-1 tw-grid-rows-1 tw-w-6 tw-text-center tw-justify-items-center tw-items-center">
<!--        <span :style="{background: colorMapLevel.background}" class="dot tw-col-span-full tw-row-span-full"></span>-->
<!--        <span :style="{color: colorMapLevel.color}" class="tw-text-red-500 tw-col-span-full tw-row-span-full tw-text-[9px]">{{item.details.level}}</span>-->
        <img :src="levelComputed" alt="">
      </div>
    </div>
    <div class="tw-col-span-2 tw-grid tw-grid-cols-2 tw-gap-x-4" style="align-content: start">
      <div class="tw-relative tw-grid tw-grid-cols-1 tw-grid-rows-1 tw-w-6 tw-text-center tw-justify-items-center tw-items-center">
        <v-btn @click="changeHide" icon small class="ma-0">
          <img :src="isiHideLoading ? this.fillHide : this.hide" class="tw-w-4 tw-h-4">

        </v-btn>
      </div>
      <div class="tw-relative tw-grid tw-grid-cols-1 tw-grid-rows-1 tw-w-6 tw-text-center tw-justify-items-center tw-items-center" >
        <v-btn @click="changeBookmark" icon small class="ma-0">
          <img v-if="like" src="../../assets/svg/like.svg">
          <img v-else src="../../assets/svg/Icons.svg">
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import {changeMarked, changeGotIt} from '@/api/movieWaveAPI'
import level_1 from "../../assets/level_1.svg"
import level_2 from "../../assets/level_2.svg"
import level_3 from "../../assets/level_3.svg"
import level_4 from "../../assets/level_4.svg"
import level_5 from "../../assets/level_5.svg"
import level_6 from "../../assets/level_6.svg"
import hide from "../../assets/svg/hide.svg"
import fillHide from "../../assets/svg/hideFill.svg"
export default {
  name: "wordComponent",
  props:{
    item:{},
    index : {},
    typeOfShow : {}
  },
  watch:{
    item(newValue){
      this.like = newValue.bookmarked;
    }
  },
  data:()=>({
    like: false,
    isHide: false,
    hide,
    fillHide,
    level_1,
    level_2,
    level_3,
    level_4,
    level_5,
    level_6,
    isiHideLoading : false


  }),
  computed:{
    levelComputed(){
      switch (this.item.details.level){
        case 6:
          return this.level_1
        case 5:
          return this.level_2
        case 4:
          return this.level_3
        case 3 :
          return this.level_4
        case 2:
          return  this.level_5
        case 1:
          return this.level_6
      }
      return ''
    },
    colorMapType(){
      if (this.item.details?.part_of_speech_abbreviation === 'N')
        return {background: '#FFF2D8' , color: '#F6A23F'}
      if (this.item.details?.part_of_speech_abbreviation === 'Adv')
        return {background: '#F5E9FF' , color: '#B771EF'}
      if (this.item.details?.part_of_speech_abbreviation === 'V')
        return {background: '#FFD8ED' , color: '#D976AC'}
      if (this.item.details?.part_of_speech_abbreviation === 'P')
        return {background: '#F4EFFF' , color: '#7A3FF6'}
      if (this.item.details?.part_of_speech_abbreviation === 'Adj')
        return {background: '#FCFFDB' , color: '#C4D127'}
      return {background: '#FFF2D8' , color: '#F6A23F'}
    },
    colorMapLevel(){
      if (this.item.details.level == '1')
        return {background: '#F0F9F1' , color: '#68C46F'}
      if (this.item.details.level == '2')
        return {background: '#E1F3E2' , color: '#539D59'}
      if (this.item.details.level == '3')
        return {background: '#F5FBFF' , color: '#548BC7'}
      if (this.item.details.level == '4')
        return {background: '#FEEDED' , color: '#F75050'}
      if (this.item.details.level == '5')
        return {background: '#FDDCDC' , color: '#A03030'}
      return {background: '#FDCACA' , color: '#340909'}
    },
  },
  methods:{
    async changeBookmark(){
      this.like = !this.like;
      try {
        await changeMarked({
          eduPoint_id: this.item.id,
          bookmarked: this.like
        })
      }
      catch{
      //
      }
      this.$emit("initialWord",true)

    },
    addToArray(){
      this.$emit("addNewWord",this.item)
    },
    async changeHide(){
      this.isHide = !this.isHide;
      this.isiHideLoading = true
      try {
        await changeGotIt({
          eduPoint_id: this.item.id,
          got_it: this.isHide
        })
      }
      catch{
      //
      }
      this.$emit("initialWord",true)

    },
  },
  mounted() {
    if (this.item.bookmarked !== undefined){
      this.like = this.item.bookmarked;
    }
  }
}
</script>

<style scoped>
.dot {
  height: 20px;
  width: 20px;
  /*background-color: #bbb;*/
  border-radius: 50%;
  display: inline-block;
  /*position: absolute;*/
}

</style>