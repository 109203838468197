    const getAllMessagesAPI = async function (params){
    return allMessagesData(params).then((value)=>{
        return value
    })
}


const getGuidanceMessagesAPI= async function(){
    return guidanceMessages().then((value)=>{
        return value
    })
}


const guidanceMessages=()=>new Promise(function (resolve){
    resolve({
        data : {
            count : 4,
            results : [
                { text: "Please explain briefly" },
                { text: "What was the subject of the movie?" },
                { text: "Please explain briefly" },
                { text: "Please explain briefly" }
            ]
        }
    })
})


const allMessagesData=()=>new Promise(function (resolve){
    resolve({
        data : {
            count : 4,
            results : [
                {owner : 'user', content : 'please introduce AI for me'},
                {owner : 'server', content : 'Artificial Intelligence is a method of making a computer, a computer-controlled robot, or a software think intelligently like the human mind. AI is accomplished by studying the patterns of the human brain and by analyzing the cognitive process. The outcome of these studies develops intelligent software and systems'},
                {owner: 'user' , content: 'Thanks My bro'},
                {owner: 'server',content:  'your welcome my dear'}
            ]
        }
    })
})


export {
    getAllMessagesAPI,
    getGuidanceMessagesAPI,
}