import httpClient from "@/api/httpClient";


const getLanguagesAPI = () => httpClient.get('/setting/get-languages/');

const changeLanguage = (params) => httpClient.post('/setting/change-languages/', params);

const getShowMeAPI = () => httpClient.get('/setting/get-show-me/');

const changeShowMe = (params) => httpClient.post('/setting/change-show-me/', params);


export {
    getLanguagesAPI,
    changeLanguage,
    getShowMeAPI,
    changeShowMe,


}
