<template>
  <div class="tw-grid tw-grid-rows-[50px_1fr] tw-pb-3 tw-h-screen tw-bg-white">
    <div class="tw-px-6 tw-py-0 tw-flex tw-justify-between tw-items-center tw-border-b tw-border-b-[#F2F2F2]" >

      <div class="tw-flex tw-items-center tw-gap-x-2">
        <v-icon class="tw-w-6 tw-h-6" color="#0B131A" @click="backToOneFilmPage">mdi-chevron-left</v-icon>
        <span class="tw-text-base">{{getCurrentFilm?.results?.title}}</span>
      </div>
      <div class="tw-flex tw-gap-x-4">
        <v-btn class="ma-0" icon small  @click="changeTypeOfShow">
          <img :src="typeOfShow === 0 ? maximize : minimize" alt="">
        </v-btn>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-px-6 tw-overflow-hidden">
      <v-tabs class="tabs" v-model="currentTab" background-color="white" hide-slider  >
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===0 ? 'color : black': 'color : #C4C4C4' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <span>All</span>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-black tw-rounded-full" v-if="currentTab === 0"></div>
          </div>
        </v-tab>
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===1 ? 'color : black': 'color : #C4C4C4' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <span>Bookmark</span>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-black tw-rounded-full" v-if="currentTab ===1"></div>
          </div>
        </v-tab>
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===2 ? 'color : black': 'color : #C4C4C4' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <span>Got it</span>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-black tw-rounded-full" v-if="currentTab === 2"></div>
          </div>
        </v-tab>
      </v-tabs>
      <v-progress-circular v-if="loading" indeterminate size="20"  class="tw-mx-auto tw-my-auto" color="#1c78f2"/>
      <div class="tw-flex tw-flex-1 tw-mt-4 tw-gap-y-4 tw-overflow-y-scroll tw-flex-col"  v-else >
        <FilmOneWord @fetchData="fetchData" v-for="(word,index) in wordBankComputed" :key="index" :word="word"></FilmOneWord>
      </div>

    </div>

  </div>
</template>

<script>
import minimize from "@/assets/svg/minimize.svg"
import maximize from "@/assets/svg/changeScreen.svg"
import {mapActions, mapGetters} from "vuex";
import FilmOneWord from "@/components/FilmOneWord.vue";
export default {
  name : "FilmWords",
  components: {FilmOneWord},
  props:{
    typeOfShow:{},
  },
  data:()=>({
    maximize,
    minimize,
    currentTab :0,
    loading : false
  }),
  methods:{
    ...mapActions({
      fetchCurrentFilmWords : "movies/fetchCurrentFilmWords"
    }),
    changeTypeOfShow() {
     this.$emit("changePage",0)
    },
    backToOneFilmPage(){
      this.$router.push(`/film/${this.$route.params.film_id}`)

    },
    async fetchData(){
      this.loading = true
      await this.fetchCurrentFilmWords(this.payload)
      this.loading = false
    }
  },
  async mounted() {
    await this.fetchCurrentFilmWords(this.payload)
  },
  watch:{
    async currentTab(){
      this.loading = true
      try {
        await this.fetchCurrentFilmWords(this.payload)
      }
      catch (e) {
        console.log(e);
      }
      this.loading = false
    }
  },
  computed:{
    ...mapGetters({
      getCurrentFilm : "movies/getCurrentFilm",
      getCurrentFilmWords : "movies/getCurrentFilmWords"
    }),
    payload(){
      return{
        film_id : this.$route.params.film_id,
        type : this.currentTab === 1 ? 'bookmarked' : this.currentTab === 2 ? 'got_it' : null
      }
    },
    wordBankComputed(){
      return this.getCurrentFilmWords
    }
  }
}

</script>

<style >
.tabs .v-tab:before{
  background-color: white !important;
}
.tabs {
  flex-grow:0 !important ;
  flex-shrink: 0 !important;
}

</style>