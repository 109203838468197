<template>
  <div class="tw-bg-[#6196CF] tw-flex  tw-h-screen tw-flex-col tw-gap-y-6">
    <div class="tw-flex tw-gap-x-4 tw-pt-6 tw-px-6">
      <v-icon color="#F5F7F6" size="24" @click="back">mdi-chevron-left</v-icon>
      <span class="tw-text-2xl tw-text-[#F5F7F6]">words</span>
    </div>
    <search-box class="tw-px-6" placeholder="Search" background-color="#81ABD9" :dark="true" magnify-color="#F5F7F6" />
    <div class="tw-flex tw-flex-col tw-overflow-hidden tw-px-6">
      <v-tabs v-model="currentTab" background-color="transparent" hide-slider  >
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===0 ? 'color : #F5F7F6': 'color : #C0D5EC' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <span>All</span>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-white tw-rounded-full" v-if="currentTab === 0"></div>
          </div>
        </v-tab>
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===1 ? 'color : #F5F7F6': 'color : #C0D5EC' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <span>Bookmark</span>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-white tw-rounded-full" v-if="currentTab ===1"></div>
          </div>
        </v-tab>
        <v-tab style="font-size: 12px; min-width: 0" :style="currentTab ===2 ? 'color : #F5F7F6': 'color : #C0D5EC' "  class="tw-normal-case">
          <div class="tw-relative tw-h-full tw-flex tw-w-full tw-justify-center tw-items-center" >
            <span>Got it</span>
            <div class="tw-w-1 tw-absolute tw-top-10 tw-bottom-0 tw-h-1 tw-bg-white tw-rounded-full" v-if="currentTab === 2"></div>
          </div>
        </v-tab>
      </v-tabs>
    </div>

    <div class="tw-flex tw-flex-1 tw-gap-y-4 tw-overflow-y-scroll tw-h-12 tw-bg-white tw-flex-col tw-p-6 tw-gap-y-6" style="border-radius: 24px 24px 0 0"  >
      <v-progress-circular v-if="loading" indeterminate size="20"  class="tw-mx-auto tw-my-auto" color="#1c78f2"/>
      <FilmOneWord @fetchData="fetchData" v-for="(word,index) in userWordBankComputed" :key="index" :word="word" v-else></FilmOneWord>
    </div>
  </div>
</template>

<script>
import SearchBox from "@/components/common/searchBox.vue";
import FilmOneWord from "@/components/FilmOneWord.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name : 'UserWords',
  data:()=>({
    currentTab : 0,
    loading : false

  }),
  async mounted() {
    this.loading = true
    await this.fetchUserWords(this.payload)
    this.loading = false
  },
  watch:{
    async currentTab(){
      this.loading = true
      await this.fetchUserWords(this.payload)
      this.loading = false
    }
  },
  methods:{
    ...mapActions({
      fetchUserWords : "userprofile/fetchUserWords"
    }),
    back(){
      this.$router.push("/")
    },
    async fetchData(){
      this.loading = true
      await this.fetchUserWords(this.payload)
      setTimeout(()=>{
        this.loading = false
      },2000)
    }
  },
  computed:{
    ...mapGetters({
      getUserWords : "userprofile/getUserWords"
    }),
    userWordBankComputed(){
      return this.getUserWords
    },
    payload(){
      return {
        type : this.currentTab === 0 ? null : this.currentTab === 1 ? 'bookmarked'  : 'got_it'
      }
    }
  },
  components: { FilmOneWord,SearchBox}
}

</script>

<style>
.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before{
  opacity: 0!important;
}


</style>