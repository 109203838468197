<template>
  <div  class="tw-flex tw-flex-col  tw-px-2 tw-overflow-y-scroll tw-items-end " :class="typeOfShow === 1 ? 'tw-border-t tw-border-t-[#F3F3F3] tw-bg-[#F5F7F6] tw-flex-1  ' : 'tw-max-h-[96px] tw-border-y tw-border-y-[#F3F3F3]'">
    <div v-for="(word,index) in chatWords" class="tw-flex tw-gap-x-2 tw-items-center tw-justify-end tw-py-2 tw-px-4 tw-w-full " :key="index" @click="changeChat(word)" :class="[getCurrentChat?.item_id === word?.item_id && getCurrentChat?.avatar === word?.avatar ? 'tw-bg-[#ebebeb]' : '']" >
      <span class="tw-text-xs tw-text-black">{{word.name}}</span>
      <div class="tw-flex tw-w-6 tw-h-6 tw-justify-center tw-items-center tw-rounded-[42px]"  :style="`backgroundColor : ${colorMapType(word.avatar).background}`">
        <span class="tw-text-fs9" :style="`color : ${colorMapType(word.avatar).color}`">{{word.avatar}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name : "ChatWords",
  props:{
    chatWords :{},
    typeOfShow:{}
  },
  methods:{
    ...mapMutations({
      changeCurrentChat : "Chat/CHANGE_CURRENT_CHAT"
    }),
    changeChat(word){
        this.changeCurrentChat(word)
    },
    colorMapType(value){
      if (value === 'N')
        return {background: '#FFF2D8' , color: '#F6A23F'}
      if (value === 'Adv')
        return {background: '#F5E9FF' , color: '#B771EF'}
      if (value === 'V')
        return {background: '#FFD8ED' , color: '#D976AC'}
      if (value === 'P')
        return {background: '#F4EFFF' , color: '#7A3FF6'}
      if (value === 'Adj')
        return {background: '#FCFFDB' , color: '#C4D127'}
      return {background: '#FFF2D8' , color: '#F6A23F'}
    },
  },
  computed:{
    ...mapGetters({
      getCurrentChat  : 'Chat/getCurrentChat'
    }),
  },
}

</script>

<style scoped>

</style>