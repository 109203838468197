<template>
  <div class="tw-w-full tw-bg-[#F5F7F6] tw-flex tw-justify-between tw-items-center  tw-p-4 tw-rounded-[12px]">
    <div class="tw-flex tw-flex-col tw-justify-between tw-gap-y-3 tw-w-max tw-h-full">
      <span class="tw-text-black tw-text-sm">{{word.phrase}}</span>
      <span class="tw-text-[#888888] tw-text-xs">{{word.meaning}}</span>
    </div>
    <div class="tw-flex tw-justify-between tw-flex-col tw-items-center tw-w-max">
      <div class="tw-flex tw-gap-x-2">
        <div class="tw-relative tw-w-6 tw-h-6 tw-rounded-full  tw-text-center tw-flex   tw-justify-center tw-items-center" :style="{background: colorMapType.background}">
          <span :style="{color: colorMapType.color}" class="tw-text-[8px]">{{ word.details.part_of_speech_abbreviation }}</span>
        </div>
        <img :src="levelComputed" alt="">
      </div>
      <div class="tw-flex tw-gap-x-2">
        <div class="tw-relative tw-grid tw-grid-cols-1 tw-grid-rows-1 tw-w-6 tw-text-center tw-justify-items-center tw-items-center">
          <v-btn @click="changeHide" icon small class="ma-0 tw-w-6 tw-h-6">
            <img :src="word.got_it ? this.fillHide : this.hide" class="tw-w-4 tw-h-4" alt="">
          </v-btn>
        </div>
        <div class="tw-relative tw-grid tw-grid-cols-1 tw-grid-rows-1 tw-w-6 tw-text-center tw-justify-items-center tw-items-center" >
          <v-btn @click="changeBookmark" icon small class="ma-0">
            <img  :src="word.bookmarked ? bookmarked : nonBookmarked "  alt="">
          </v-btn>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import level_1 from "@/assets/level_1.svg";
import level_2 from "@/assets/level_2.svg";
import level_3 from "@/assets/level_3.svg";
import level_4 from "@/assets/level_4.svg";
import level_5 from "@/assets/level_5.svg";
import level_6 from "@/assets/level_6.svg";
import hide from "@/assets/svg/hide.svg"
import fillHide from "@/assets/svg/hideFill.svg"
import bookmarked from "@/assets/svg/like.svg"
import nonBookmarked from "@/assets/svg/Icons.svg"
import {changeGotIt, changeMarked} from "@/api/movieWaveAPI";
export default {
  name : "FilmOneWord",
  data:()=>({
    level_1,
    level_2,
    level_3,
    level_4,
    level_5,
    level_6,
    hide,
    fillHide,
    like : false,
    isHideLoading : false,
    bookmarked,
    nonBookmarked,
  }),
  props:{
    word:{}
  },
  computed:{
    colorMapType(){
      if (this.word.details?.part_of_speech_abbreviation === 'N')
        return {background: '#FFF2D8' , color: '#F6A23F'}
      if (this.word.details?.part_of_speech_abbreviation === 'Adv')
        return {background: '#F5E9FF' , color: '#B771EF'}
      if (this.word.details?.part_of_speech_abbreviation === 'V')
        return {background: '#FFD8ED' , color: '#D976AC'}
      if (this.word.details?.part_of_speech_abbreviation === 'P')
        return {background: '#F4EFFF' , color: '#7A3FF6'}
      if (this.word.details?.part_of_speech_abbreviation === 'Adj')
        return {background: '#FCFFDB' , color: '#C4D127'}
      return {background: '#FFF2D8' , color: '#F6A23F'}
    },
    levelComputed(){
      switch (this.word.details.level){
        case 6:
          return this.level_1
        case 5:
          return this.level_2
        case 4:
          return this.level_3
        case 3 :
          return this.level_4
        case 2:
          return  this.level_5
        case 1:
          return this.level_6
      }
      return ''
    },

  },
  methods:{
    async changeBookmark(){
      try {
        await changeMarked({
          eduPoint_id: this.word.id,
          bookmarked: !this.word.bookmarked
        })
        this.$emit("fetchData")
      }
      catch{
        //
      }
    },
    async changeHide(){
      try {
        await changeGotIt({
          eduPoint_id: this.word.id,
          got_it: !this.word.got_it
        })
        this.$emit("fetchData")
      }
      catch(e){
        console.log(e);
      }

    }

  },
}

</script>

<style scoped>
.dot {
  height: 20px;
  width: 20px;
  /*background-color: #bbb;*/
  border-radius: 50%;
  display: inline-block;
  /*position: absolute;*/
}
</style>