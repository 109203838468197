import httpClient from "@/api/httpClient";

const getTopMoviesAPI =(params)=>httpClient.get("https://vidipen.com/film/by-genre-list/",{
    params: {
        ...(params?.level ? {level: params?.level} : {}),
        ...(params?.search ? {search: params?.search} : {}),
    },
})

const getAllMoviesAPI=(params)=>httpClient.get("https://vidipen.com/film/list/",{
    params:{
        ...(params?.genre ? {genre: params?.genre} : {}),
        ...(params?.level ? {level: params?.level} : {}),
        ...(params?.page ? {page: params?.page} : {}),
        ...(params?.page_size ? {page_size: params?.page_size} : {}),
        ...(params?.search ? {search: params?.search} : {}),
    }

})


const getCurrentFilmPropertyAPI =(film_id)=> httpClient.get(`film/Item/${film_id}`)

export {
    getTopMoviesAPI,
    getCurrentFilmPropertyAPI,
    getAllMoviesAPI
}