<template>
  <div class="tw-flex tw-justify-between tw-w-full tw-items-center ">
    <slot></slot>
    <img  :src="getUserProfile?.avatar || userAvatar" alt="" class="tw-rounded-full tw-w-10 tw-h-10">
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import userAvatar from "../../assets/Profile.svg"

export default {
  name  : 'userTopBar',
  data:()=>({
    userAvatar
  }),
  computed:{
    ...mapGetters({
      getUserProfile : "userprofile/getUserProfile"
    })
  }
}

</script>
<style scoped>

</style>