import {getLanguagesAPI, getShowMeAPI, } from "@/api/settingAPI";
const state=({
    languages: null,
    showMe: null,
    is_playing : false,
    marginLeftParameter : 0,
    playInterval : null,
    currentTime : {time : 0},
    draggerAblePosition : null,
    changeTimeFlag : true
})

const actions={

    async fetchLanguages({commit}){
        try {
            let response = await getLanguagesAPI()
            commit("SET_LANGUAGES",response.data)
        }
        catch {
        //
        }
    },
    async fetchShowMe({commit}){
        try {
            let res = await getShowMeAPI()
            commit("SET_SHOW_ME", res.data)
        }
        catch{
        //
        }
    }
}

const mutations ={
    SET_LANGUAGES(state, data){
        state.languages = data;
    },
    SET_SHOW_ME(state, data){
        state.showMe = data;
    },
    SET_IS_PLAYING(state,data){
        state.is_playing = data
    },
    SET_MARGIN_LEFT_PARAMETER(state,data){
        state.marginLeftParameter = data
    },
    SET_PLAY_INTERVAL(state,data){
        state.playInterval = data
    },
    SET_CURRENT_TIME(state,data){
        state.currentTime = data
    },
    SET_DRAGGERABLE_POSITION(state,data){
        state.draggerAblePosition = data
    },
    SET_CHANGE_TIME_FLAG(state,data){
        state.changeTimeFlag = data
    }

}
const getters ={
    getLanguages(state){
        return state.languages
    },
    getShowMe(state){
        return state.showMe
    },
    getIsPlaying(state){
        return state.is_playing
    },
    getMarginLeftParameter(state){
        return state.marginLeftParameter
    },
    getPlayInterval(state){
        return state.playInterval
    },
    getCurrentTime(state){
        return state.currentTime
    },
    getDraggablePosition(state){
        return state.draggerAblePosition
    },
    getChangeTimeFlag(state){
        return state.changeTimeFlag
    }
}
export  default {
    namespaced : true,
    state,
    actions,
    getters,
    mutations
}
