<template>
  <div class="tw-flex tw-flex-col tw-overflow-y-scroll tw-flex-1 tw-pt-6 tw-pl-6">
    <div class="tw-flex tw-flex-col tw-gap-y-8 tw-pr-6">
      <UserTopBar>
        <template >
          <img :src="menu" alt="">
        </template>
      </UserTopBar>
      <div class="tw-flex tw-justify-between tw-items-end tw-w-full ">
        <div class="tw-flex tw-flex-col tw-gap-y-1">
          <span class="tw-text-2xl tw-font-semibold">Hey <span class="tw-font-bold">{{getUserProfile?.username}}</span></span>
          <span class="tw-text-[#CBCBCB] tw-text-sm">Tasks for Today</span>
        </div>
        <div class="tw-flex tw-flex-col tw-cursor-pointer" @click="changePage">
          <span class="tw-text-xs tw-text-[#CBCBCB]">Your Place</span>
          <span class="tw-text-xs">{{getUserProfile?.places}} / 100</span>
        </div>
      </div>
    </div>
    <TaskSlider class="tw-mt-4 tw-mb-6 tw-pr-6 tw-w-full" />
    <div class="tw-flex tw-flex-col tw-pr-6">
      <SearchBox class="tw-mb-6" @search="searchFunction" />
      <div class="tw-flex tw-flex-col tw-gap-y-1">
        <span class="tw-text-xl tw-text-[#222222] tw-font-semibold">Watching</span>
        <span class="tw-text-[#CBCBCB] tw-text-sm">Choose video to watch</span>
    </div>
      <HardnessLevel class="tw-mt-4 tw-mb-6" :active-index.sync="activeIndex" @changeActiveHardness="changeActiveHardness" />
      <div v-if="!loading">
        <TopMovieContainer :active-hardness.sync="activeIndex" class="tw-mb-8 tw-overflow-y-scroll" v-for="(movies,index) in getTopMovies.results" :key="index" :movies="movies" />
      </div>
      <v-progress-circular indeterminate v-else size="24" color="#1c78f2" class="tw-mx-auto tw-my-24" />
    </div>



  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import userAvatar from "../../assets/Profile.svg"
import menu from "../../assets/menu.svg"
import TaskSlider from "@/components/home/TaskSlider.vue";
import SearchBox from "@/components/common/searchBox.vue";
import HardnessLevel from "@/components/common/hardnessLevels.vue";
import TopMovieContainer from "@/components/common/TopMovieContainer.vue";
import UserTopBar from "@/components/common/UserTopBar.vue";
export default {
  name : "home",
  components: {UserTopBar, TopMovieContainer, HardnessLevel,  TaskSlider,SearchBox},
  data:()=>({
    userAvatar,
    menu,
    activeIndex : 1,
    loading : false,
    searchText : ''
  }),
  async mounted() {
    await this.fetchUserProfile()
    this.loading = true
    await this.fetchTopMovie(this.topMovieComputed)
    this.loading = false
  },

  methods:{
    ...mapActions({
      fetchUserProfile : "userprofile/fetchUserProfile",
      fetchTopMovie : "movies/fetchTopMovie"
    }),
    async changeActiveHardness(value){
      this.loading = true
      this.activeIndex = value
      await this.fetchTopMovie(this.topMovieComputed)
      this.loading = false
    },
    async searchFunction(value){
      this.loading = true
      this.searchText = value
      await this.fetchTopMovie(this.topMovieComputed)
      this.loading = false
    },
    changePage(){
      this.$router.push({name : 'userWord'})
    }
  },
  watch:{

  },
  computed:{
    ...mapGetters({
      getUserProfile : "userprofile/getUserProfile",
      getTopMovies : "movies/getTopMovies"
    }),
    topMovieComputed(){
      return {
        level : this.activeIndex,
        search : this.searchText
      }
    }
  }
}

</script>


<style scoped>

</style>